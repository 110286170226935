<template>
  <div style="width: 100%; height: 100%" id="map"></div>
  <resize-observer @notify="handleResize"></resize-observer>
</template>

<script>
export default {
  name: "MapComponent"
}
</script>
<script setup>
import {defineEmits} from 'vue'
import 'leaflet/dist/leaflet.css';
import  L  from 'leaflet';
import 'leaflet-svg-shape-markers';
import {computed, onMounted, watch} from "vue";
import epww from '../sectors/EPWW.json'

const fixes = {
  EPWW: epww
}

// eslint-disable-next-line no-unused-vars
import fixesJson from '../sectors/EPWW.json'

// eslint-disable-next-line no-undef
const props = defineProps({
  position: String,
  firs: Object,
  fir: String
})

let map = null
const borderGroup = new L.LayerGroup();
const fixesGroup = new L.LayerGroup();

const viewBounds = [[55.936894769039434, 14.015993985031855, 13.092993048221214],[48.73445537176822, 24.274599733751973]];
const center = [49.814948620925776, 15.495968073068102];

// eslint-disable-next-line no-unused-vars
const fixIcon = L.icon({
  iconUrl: '/assets/fix.png',
  iconSize: [10, 10],
  iconAnchor: [10, 10],
  popupAnchor: [-5, -10],
});

// Setup map view whem viewport changes
const handleResize = () => {
  map.fitBounds(viewBounds)
}



onMounted(() => {
  initMap()
  // freezeMap()
})

// Create map and layers
const initMap = () => {
  map = L.map('map', { zoomControl: false}).setView(center, 13, );
  map.fitBounds(viewBounds)

  L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
    attribution: '© OpenStreetMap contributors'
  }).addTo(map);

  map.addLayer(borderGroup)
  map.addLayer(fixesGroup)

  map.on('moveend', function() {
    console.log(map.getBounds())
    console.log(map.getCenter())
  });

  map.on('click', (ev) => {
    var latlng = map.mouseEventToLatLng(ev.originalEvent);
    console.log(latlng.lat + ', ' + latlng.lng);
  })
}

// eslint-disable-next-line no-unused-vars
const freezeMap = () => {
  map.dragging.disable();
  map.touchZoom.disable();
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.boxZoom.disable();
  map.keyboard.disable();
}

watch(() => props.position, () => {
  console.log(props.position)
})

watch(() => props.fir, () => {
  const firBorder = props.firs.filter((fir) => fir.id == props.fir)[0]
  borderGroup.clearLayers();
  L.geoJSON(firBorder).addTo(borderGroup)
  // eslint-disable-next-line no-prototype-builtins
  if(firBorder.properties.hasOwnProperty('viewBounds')) {
    map.fitBounds(firBorder.properties.viewBounds)
  }
  else {
    map.fitBounds([[68.6245436634471, -5.717943744537163],[34.88593094075317, 41.8178589405748]])
  }
  initFixes()
})

const initFixes = () => {
  fixesGroup.clearLayers()
  L.geoJSON(firFixes.value, {
    pointToLayer: (feature, latlng) => {
      return L.shapeMarker(latlng, {
        shape: "square",
        radius: 8,
        fillColor: "#3e456c",
        color: "black",
        className: 'fix'
      })
          .bindTooltip(feature.properties.name,
              {
                permanent: true,
                direction: 'right',
                offset: [0,20]
              })
          .on('click', () => {
        emit('showFixInfo', feature.properties)
      });
      // return L.marker(latlng, { icon: fixIcon}).bindTooltip(feature.properties.name,
      //     {
      //       permanent: true,
      //       direction: 'right',
      //       offset: [-5,10]
      //     })
      // })
    }
  })
  .addTo(fixesGroup)
}

watch(() => props.firs, () => {
  borderGroup.clearLayers();
  L.geoJSON({"type": "FeatureCollection", "features": props.firs}).addTo(borderGroup)
})


// eslint-disable-next-line no-unused-vars
const firFixes = computed(() => {
    return fixes[props.fir]

})

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['showFixInfo'])

</script>

<style scoped>
</style>