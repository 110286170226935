<template>
  <div class="modal" id="exampleModal" tabIndex="-1" aria-labelledby="" aria-hidden="true" ref="modalEle">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ _fix.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="_fix.image" class="mb-2 text-center">
            <img :src="_fix.image" class="w-100">
          </div>
          <div v-else class="mb-2">No Image defined</div>
          <div>{{ _fix.description}}</div>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {Modal} from "bootstrap";

// eslint-disable-next-line no-undef
defineProps({
  title: {
    type: String,
    default: "<<Title goes here>>",
  },
});
let modalEle = ref(null);
let thisModalObj = null;
let _fix = ref({ name: 'test'});

onMounted(() => {
  thisModalObj = new Modal(modalEle.value);
});

function _show(fix) {
  _fix.value = fix
  thisModalObj.show();
}

// eslint-disable-next-line no-undef
defineExpose({show: _show});
</script>

