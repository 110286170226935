<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-10 vh-100">
        <MapComponent :firs="positionFirs" :fir="selectedFir" @show-fix-info="showFixInfo"></MapComponent>
      </div>
      <div class="col-2 pt-3">
        <div class="d-flex justify-content-between" v-if="selectedPosition">
          <h2 class="m-0 p-0">{{ selectedPosition }}</h2>
          <button class="btn btn-sm btn-outline-danger" @click="selectedPosition=null">x</button>
        </div>


        <ul class="list-group text-center" v-else>
          <a class="list-group-item" :class="{ active: position === selectedPosition }" href="javascript:void(0)" v-for="(position, index) in eurocontrolPositions" :key="index" @click="selectedPosition = position">{{ position}}</a>
        </ul>


        <div v-if="selectedPosition" class="mt-3">
          <ul class="list-group">
            <a href="javascript:void(0)" class="list-group-item d-flex justify-content-between align-items-center" :class="{active: fir.id === selectedFir}" v-for="(fir, index) in positionFirs" :key="index" @click="selectedFir = fir.id">
              {{ fir.id }} - {{ fir.properties.name}}<span class="badge badge-pill bg-danger" v-if="!fir.properties.viewBounds">!</span>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <FixModal ref="fixModal"></FixModal>
  </div>

</template>

<script>
export default {
  name: 'LoasApp',
}
</script>
<script setup>
import {computed, ref} from "vue";
import MapComponent from "@/components/Map.vue";
import FixModal from "@/components/FixModal.vue";

// eslint-disable-next-line no-unused-vars
import firJson from './sectors/coordinates.json';
// eslint-disable-next-line no-unused-vars
import fixesJson from './sectors/EPWW.json'

const fixModal = ref(null)
const selectedPosition = ref(null)
const selectedFir = ref(null)

// Unique Eurocontrol positions
const eurocontrolPositions = computed(() => {
  return firJson.features.map((fir) => fir.properties.group)
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort()
})

const positionFirs = computed(() => {
  return firJson.features.filter((feature) => feature.properties.group === selectedPosition.value)
})


const showFixInfo = (fix) => {
  fixModal.value.show(fix)
}




</script>

<style>
</style>
